var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.itemId || _vm.itemData
    ? _c(
        "div",
        {
          staticClass: "bibliotron-exercise perseus-root",
          class: { "perseus-mobile": _vm.isMobile },
        },
        [
          _c(
            "div",
            {
              staticClass: "framework-perseus",
              style: { margin: _vm.isMobile ? "0" : "0 24px" },
            },
            [
              _c(
                "div",
                {
                  ref: "perseus",
                  staticClass: "perseus",
                  attrs: { id: "perseus" },
                },
                [
                  _c(
                    "div",
                    { staticClass: "loader-container" },
                    [
                      _c("KLinearLoader", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.loading,
                            expression: "loading",
                          },
                        ],
                        attrs: { delay: false, type: "indeterminate" },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "KGrid",
                    [
                      _c(
                        "KGridItem",
                        {
                          attrs: {
                            layout12: {
                              span: _vm.allowHints && _vm.interactive ? 6 : 12,
                            },
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "problem-area",
                              attrs: {
                                id: "problem-area",
                                dir: _vm.contentDirection,
                              },
                            },
                            [
                              _c("div", {
                                staticStyle: {
                                  "margin-left": "0px",
                                  "margin-right": "0px",
                                },
                                attrs: { id: "workarea" },
                              }),
                            ]
                          ),
                        ]
                      ),
                      _c(
                        "KGridItem",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.interactive && _vm.allowHints,
                              expression: "interactive && allowHints",
                            },
                          ],
                          attrs: { layout12: { span: 6 } },
                        },
                        [
                          _vm.hinted
                            ? _c(
                                "div",
                                {
                                  staticClass: "hintlabel",
                                  attrs: {
                                    id: "hintlabel",
                                    dir: _vm.contentDirection,
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(_vm.$tr("hintLabel")) +
                                      "\n          "
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _c("div", {
                            staticClass: "hintsarea",
                            attrs: {
                              id: "hintsarea",
                              dir: _vm.contentDirection,
                            },
                          }),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("transition", { attrs: { name: "expand" } }, [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.message,
                        expression: "message",
                      },
                    ],
                    attrs: { id: "message", dir: _vm.contentDirection },
                  },
                  [_vm._v("\n        " + _vm._s(_vm.message) + "\n      ")]
                ),
              ]),
              _c(
                "div",
                {
                  attrs: { id: "answer-area-wrap", dir: _vm.contentDirection },
                },
                [_vm._m(0)]
              ),
              _vm.scratchpad
                ? _c("KButton", {
                    attrs: {
                      id: "scratchpad-show",
                      primary: false,
                      raised: false,
                      text: _vm.$tr("showScratch"),
                    },
                  })
                : _c("KButton", {
                    attrs: {
                      id: "scratchpad-not-available",
                      primary: false,
                      raised: false,
                      disabled: "",
                      text: _vm.$tr("notAvailable"),
                    },
                  }),
              _c("div", {
                ref: "perseusContainer",
                attrs: { id: "perseus-container", dir: _vm.contentDirection },
              }),
            ],
            1
          ),
        ]
      )
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { attrs: { id: "answer-area" } }, [
      _c("div", { staticClass: "info-box" }, [
        _c("div", {
          staticClass: "solutionarea",
          attrs: { id: "solutionarea" },
        }),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }